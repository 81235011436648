<template>
    <v-app>
        <v-container>
            <!-- header -->
            <v-row>
                <v-col cols="3"><h4>Tiêu đề câu hỏi</h4></v-col>
                <v-col cols="9">
                    <v-text-field
                            v-model="question.content"
                            label="Nhập câu hỏi"
                            outlined
                    ></v-text-field>
                </v-col>
            </v-row>
            <!-- main -->
            <!-- select block -->
            <v-row class="pl-2">
                <v-col class="pa-5" cols="12" sm="12" md="12">
                    <v-col v-if="isSelectBox" class="justify-center d-flex">
                        <v-radio-group v-model="question.question_type" row>
                            <v-radio label="Chọn đáp án" :value="1"></v-radio>
                            <v-radio label="Viết đáp án" :value="2"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-col>
            </v-row>
            <!-- write answer block -->
            <v-row v-if="question.question_type == 2">
                <v-col cols="6">
                    <h6>Đoạn văn bị thiếu</h6>
                    <CkContent
                            validateName="answer"
                            :content="question.filled_dialog.missing_sentence"
                            @getData="getMissingSentenceContent"
                    ></CkContent
                    >
                </v-col>
                <v-col cols="6">
                    <h6>Đáp án</h6>
                    <CkContent
                            validateName="answer"
                            :content="question.filled_dialog.complete_sentence"
                            @getData="getCompleteSentenceContent"
                    ></CkContent
                    >
                </v-col>
            </v-row>
            <!-- select answer block -->
            <v-row v-if="question.question_type == 1">
                <v-col cols="10">
                    <h6>Nhập đáp án</h6>
                </v-col>
                <v-col cols="2">
                    <v-btn color="black" class="white--text" @click="addAnswer"
                    >Thêm đáp án
                    </v-btn
                    >
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                    :headers="headers"
                                    :items="question.answers"
                                    :items-per-page="10"
                                    class="elevation-1 text-center"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
                                </template>
                                <template v-slot:item.check="{ item }">
                                    <v-checkbox v-model="item.check"></v-checkbox>
                                </template>
                                <template v-slot:item.content="{ item }">
                                    <v-text-field dense v-model="item.content"></v-text-field>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- footer -->
            <v-row>
                <v-col class="text-center justify-center">
                    <div class="my-4">
                        <v-btn
                                class="ma-2 white--text"
                                color="blue-grey"
                                @click="$router.go(-1)"
                        >Hủy
                        </v-btn
                        >
                        <v-btn color="primary" type="submit" @click="submitQuestion"
                        >Lưu
                        </v-btn
                        >
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
<script>
    import CkContent from "@/components/ck-content";
    import {
        SAVE_EXERCISE_CONTENT,
        GET_EXERCISE_DETAIL,
        GET_QUESTION_DETAIL,
        UPDATE_EXERCISE_CONTENT,
    } from "@/store/exercises.module";
    import {mapGetters} from "vuex";

    export default {
        components: {
            CkContent,
        },
        data: () => ({
            newIndex: -1,
            test: [],
            customs_quetions: [
                {
                    d: -1,
                    content: "",
                    check: "0"
                }
            ],
            disable_btn_add: true,
            headers: [
                {text: "Đáp án", value: "content"},
                {text: "Chọn đáp án đúng", value: "check"},
                {text: "Thao tác", value: "actions", sortable: false},
            ],
            writeAnswer: 0,
            isSelectBox: true,
            isUpdate: false,
        }),
        computed: {
            ...mapGetters({
                exercise: "exerciseDetail",
                question: "questionDetail",
            }),
        },
        watch: {},
        async created() {
            await this.$store.dispatch(GET_EXERCISE_DETAIL, {
                exercise_slug: this.$route.query.exercise_slug,
            });
            await this.$store.dispatch(GET_QUESTION_DETAIL, {
                question_id: this.$route.query.question_id,
            });
            if (this.$route.query.question_id) {
                this.isSelectBox = false;
                this.isUpdate = true;
            }
        },

        methods: {
            checkProperties(obj) {
                for (var key in obj) {
                    if (obj[key] !== null && obj[key] != "")
                        return false;
                }
                return true;
            },
            addAnswer() {
                this.question.answers.push({
                    id: --this.newIndex,
                    content: "",
                    check: 0,
                });
                this.question.answers = this.question.answers.reduce((unique, o) => {
                    if (!unique.some(obj => obj.content === o.content)) {
                        unique.push(o);
                    }
                    return unique;
                }, []);


            },
            deleteItem(item) {
                const index = this.question.answers.indexOf(item);
                confirm("Bạn có chắc chắn muốn xóa không?") &&
                this.question.answers.splice(index, 1);
            },
            getMissingSentenceContent(value) {
                this.question.filled_dialog.missing_sentence = value;
            },
            getCompleteSentenceContent(value) {
                this.question.filled_dialog.complete_sentence = value;
            },
            submitQuestion() {
                if (!this.question.content) {
                    this.$toasted.error('Bạn chưa nhập câu hỏi', {
                        position: "top-right",
                        duration: 3000
                    })
                    return false
                }
                if (this.question.question_type == 1) {
                    if (this.question.answers.length == 0) {
                        this.$toasted.error('Bạn chưa nhập câu trả lời', {
                            position: "top-right",
                            duration: 3000
                        })
                        return false
                    }
                    let check = 0
                    this.question.answers.map(item => {
                        if (item.content) {
                            check++
                        }
                    })
                    if (check != this.question.answers.length) {
                        this.$toasted.error('Các câu trả lời chưa đầy đủ thông tin', {
                            position: "top-right",
                            duration: 3000
                        })
                        return false
                    }
                    if (this.countRightAnswer(this.question.answers) < 1) {
                        this.$toasted.error('Bạn chưa chọn câu trả lời đúng', {
                            position: "top-right",
                            duration: 3000
                        })
                        return false
                    }
                }
                if (this.question.question_type == 2) {
                    if (!this.question.filled_dialog.missing_sentence) {
                        this.$toasted.error('Bạn chưa nhập đoạn văn', {
                            position: "top-right",
                            duration: 3000
                        })
                        return false
                    }
                    if (!this.question.filled_dialog.complete_sentence) {
                        this.$toasted.error('Bạn chưa nhập đáp án', {
                            position: "top-right",
                            duration: 3000
                        })
                        return false
                    }
                }
                if (this.question.content) {
                    // get type of question
                    this.question.type = this.writeAnswer;
                    // fill diaglog convert to array
                    let filled_dialog = [];
                    filled_dialog.push(this.question.filled_dialog);
                    if (this.isUpdate) {
                        this.$store.dispatch(UPDATE_EXERCISE_CONTENT, {
                            question: this.question,
                            answers: this.question.answers,
                            filled_dialog: filled_dialog,
                            exercise: this.exercise,
                        }).then((data) => {
                            if (data.data.status) {
                                this.$router.go(-1);
                            }
                        });
                    }
                    if (!this.isUpdate) {
                        this.$store.dispatch(SAVE_EXERCISE_CONTENT, {
                            question: this.question,
                            answers: this.question.answers,
                            filled_dialog: filled_dialog,
                            exercise: this.exercise,
                        })
                            .then((data) => {
                                if (data.data.status) {
                                    this.$router.go(-1);
                                }
                            });
                    }
                }
            },
            countRightAnswer(answers) {
                let count = 0;
                answers.forEach((item) => {
                    if (item.check) {
                        count++;
                    }
                })
                return count;
            },
        },
    };
</script>
